<template>
  <v-container>
    <v-col cols="12" class="text-center">
      <v-card-title class="headline">{{
        $t("users.your-taxes")
      }}</v-card-title></v-col
    >
    <v-expansion-panels>
      <template v-for="(url, title) in videos">
        <help-video-frame
          :key="title"
          :videoTitle="title"
          :videoSource="url"
        ></help-video-frame>
      </template>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import HelpVideoFrame from "../helpvideos/HelpVideoFrame.vue";
export default {
  components: { HelpVideoFrame },
  data() {
    return {
      videos: {
        "users.salary-taxes-book": "https://www.youtube.com/embed/E4Q_HK9X28k",
        "users.salary-taxes-declaration":
          "https://www.youtube.com/embed/WW_epWcJDDY",
      },
    };
  },
};
</script>
